import React, { useState, useEffect } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import helpers from "@drapp/helpers";
import { ReactComponent as IconInfoCircle } from 'bootstrap-icons/icons/info-circle.svg'

import Dropdown from "./Dropdown";


function Emails(props) {
  const { t } = useTranslation();
  const [modal, setModal] = useState();
  const [state, setState] = useState([]);

  const slice = (index1) => {
    const newState = state.filter((item, index2) => index1 !== index2);
    setState(JSON.clone([...newState]));
    props.onChange(JSON.clone([...newState]));
  };

  const add = () => {
    setState([...state, { ...props.options }]);
  };

  const onChange = (index) => ({ target: { name, value, type } }) => {
    if (value && type === "number") {
      state[index][name] = +value;
    } else if (type === "checkbox") {
      state[index][name] = !state[index][name];
    } else {
      state[index][name] = value;
    }
    const array = JSON.clone([...state]);
    setState(array);
    props.onChange(array);
    setModal();
  };

  useEffect(() => {
    setState([...props.value]);
  }, [props.value]);

  return (
    <div className="mb-3">
      <div className="d-flex align-items-center form-label">
        <label htmlFor="emails">
          {t("email_plural", { context: props.context })}
        </label>
        {!props.disabled && (
          <button onClick={add} type="button" className="btn btn-link p-0 ms-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-plus-square-fill"
              viewBox="0 0 16 16"
            >
              <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
            </svg>
          </button>
        )}
      </div>
      {state.map((email, index) => (
        <div
          className={cx("position-relative", {
            "mb-1": index !== state.length - 1,
          })}
          key={`email${index}`}
        >
          <input
            type="email"
            name="value"
            autoComplete="--disable"
            required={props.required}
            disabled={!!props.disabled}
            id={`email${index}`}
            placeholder={t("email_placeholder")}
            value={email.value || ""}
            onChange={onChange(index)}
            className="form-control"
          />
          <div className="form-control-actions d-flex align-items-center gap-1">
            {email.source && email.source !== "drapp" &&
              <div>
                <IconInfoCircle
                  width="14"
                  height="14"
                  data-tip={`
                    <div>
                      ${t("entered_by_patient" ,{context: props.context, defaultValue: "Ingresado por paciente"})}
                    </div>`
                  }
                />
              </div>
            }
            {!props.disabled &&
              helpers.isEmail(email.value) &&
              Object.keys(props.options || {}).length > 0 && (
                <Dropdown
                  value={email}
                  options={props.options}
                  onChange={onChange(index)}
                />
              )}
            {!props.disabled && (
              <button
                className="btn btn-sm text-danger py-0 px-1"
                type="button"
                onClick={() => slice(index)}
              >
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  className="bi bi-x"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.854 4.146a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708l7-7a.5.5 0 0 1 .708 0z"
                  />
                  <path
                    fillRule="evenodd"
                    d="M4.146 4.146a.5.5 0 0 0 0 .708l7 7a.5.5 0 0 0 .708-.708l-7-7a.5.5 0 0 0-.708 0z"
                  />
                </svg>
              </button>
            )}
          </div>
        </div>
      ))}
      {state.length > 0 && (
        <div className="d-flex align-items-center justify-content-between">
          <div
            className="form-text"
            dangerouslySetInnerHTML={{
              __html: t(`email_hint`, { context: props.context }),
            }}
          />
        </div>
      )}
    </div>
  );
}
export default Emails;
